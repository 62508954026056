<template>
    <div class="single-file-alert alert">
        <div class="single-file-box alert_box">
            <h3>해당 음원은 본사에서 일괄적으로 재생하는 음원입니다.</h3>
            <div class="single-file-check">
                <p @click="$emit('closeModal')">확인</p>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  name: 'AlertBrandMusic-Player'
});
</script>

<style scoped>
  .modal {
    z-index: 1057;
  }
  p {
    text-align: center;
  }
</style>
