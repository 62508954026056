<template>
    <div class="single-file-alert alert">
        <div class="single-file-box alert_box">
            <h3>음원 배포 전 시간 설정을 완료해 주세요.</h3>
            <div class="single-file-check">
                <p @click="$emit('closeModal')">확인</p>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  name: 'BrandMusicReleaseAlert'
});
</script>

<style scoped>
  h3 { margin-bottom: 0; }
</style>
<style src="@/assets/css/content2.css"></style>
<style src="@/assets/css/font.css"></style>
